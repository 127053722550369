.PageContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.SvgRow {
  width: 90vmin;
  height: 45vmin;
  justify-self: center;
}
.Face {
  width: 90vmin;
  height: 90vmin;
  margin: 20px;
  align-self: center;
  justify-self: center;
}
.FaceQuarter {
  height: auto;
  width: 50%;
  padding: 0;
  margin: 0;
  border: 0;
}
.FaceNumberText {
  text-align: center;
  color: #999;
  font-size: 2em;
  font-style: bold;
  font-family: monospace;
}
.Rot0 {
  transform: rotate(0deg);
}
.Rot90 {
  transform: rotate(90deg);
}
.Rot180 {
  transform: rotate(180deg);
}
.Rot270 {
  transform: rotate(270deg);
}
.ShuffleButton {
  width: 90vmin;
  margin-top: 20px;
  font-size: 6em;
  padding-top: 2em;
  align-self: center;
  background-color: #aaa;
}
.SettingsDrawerHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1vw;
}
.Countdown {
  height: 100%;
  width: 100%;
  font-size: 30vmin;
  display: flex;
  justify-content: center;
  align-items: center;
}
.SliderContainer {
  height: 20vh;
  padding-left: 3vw;
  margin-bottom: 4vh;
}
.CountdownSlider {
  margin: 1vw;
}
.Hidden {
  visibility: hidden;
}
